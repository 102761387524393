import intlTelInput from 'intl-tel-input';

document.querySelectorAll('input.phone').forEach(function(el) {
    let iti = intlTelInput(el, {
        defaultCountry: 'be',
        nationalMode: false,
        preferredCountries: ['be', 'nl', 'lu'],
        responsiveDropdown: true,
        utilsScript: "/js/phone-utils.js"
    });

    function getDefaultDailCode() {
        return '+' + iti.getSelectedCountryData().dialCode
    }

    el.onfocus = function() {
        if(iti.getNumber().length === 0) {
            el.value = getDefaultDailCode();
        }
    }
    el.keyup = function() {
        if(iti.getNumber().length === 0) {
            el.value = getDefaultDailCode();
        } else {
            el.value = iti.getNumber();
        }
    }

    if (el.classList.contains('validate') && el.classList.contains('v-phone')) {
        el.onblur = function() {
            if ((el.required || el.value.trim() && el.value !== getDefaultDailCode() && !iti.isValidNumber())) {
                el.classList.add('is-invalid');
            } else {
                el.classList.remove('is-invalid');
            }
        }
    }
})
