import Modal from 'bootstrap/js/src/modal';

window.askConfirmation = function (id, content, selector) {
    if(event) {
        event.preventDefault();
    }
    let modal = document.querySelector('.confirm-modal' + (selector === undefined ? '' : selector));
    modal.querySelector('.modal-body p').innerHTML = content;
    let bsModal = Modal.getInstance(modal);
    console.log(bsModal);
    if(bsModal == null) {
        bsModal = new Modal(modal, {
            focus: true
        });
    }
    modal.querySelector('.btn-confirm').onclick = function() {
        document.querySelector('#' + id).submit();
        bsModal.hide();
    }
    bsModal.show();
}

let cookies = document.getElementById('cookie-notification');
if(cookies) {
    let modal = new Modal(cookies, {
        backdrop: 'static',
        keyboard: false,
        focus: true
    });
    modal.show();
}
