let timer = document.getElementById("timer");
if(timer !== null) {
    setInterval(function () {
        // Find the distance between now and the count down date
        const distance = timer.dataset.time - Math.floor(new Date().getTime() / 1000);
        // Time expired
        if (distance < 0) {
            location.reload();
        } else {
            // Time calculations for days, hours, minutes and seconds
            const minutes = Math.floor(distance / 60);
            const seconds = Math.floor(distance % 60);
            // Display the result in the element with id="demo"
            timer.innerHTML = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        }
    }, 1000);
}
